// @import 'bootstrap-grid';
@import 'normalize';
@import 'responce';
@import 'fonts';
@import 'variebles';
@import 'header';
@import 'slick';
@import 'slick-theme';
@import 'home';
@import 'footer';

html, body {
    overflow: hidden;
    overflow-y: auto;
    &.overflow {
        overflow: hidden;
        // position: fixed;
    }
}

.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
    @include mediaw(1200px){
        max-width: 960px;
    }
    @include mediaw(992px){
        max-width: 720px;
    }
    @include mediaw(768px){
        max-width: 540px;
    }
    @include mediaw(576px){
        max-width: none;
    }
}

.section__title {
    font-family: $fontComfortaaBold;
    font-size: 38px;
    line-height: 42px;
    @include mediaw(480px) {
        font-size: 28px;
        line-height: 32px;
    }
}

.section__description {
    max-width: 500px;
    margin-top: 30px;
    font-family: $fontMyriadRegular;
    font-size: 16px;
    line-height: 24px;
    @include mediaw(480px) {
        font-size: 14px;
        line-height: 18px;
    }
}