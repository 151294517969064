.googoo-kyiv {
    background-image: linear-gradient(to left, #fed200, #ec3386);
    color: #fff;
    padding: 0 0 45px;
    @include mediaw(992px) {
        padding: 0 0 75px;
    }
    &__title {
        @include mediaw(480px) {
            font-size: 32px !important;
            line-height: 35px !important;
        }
    }
    &__description {
        @include mediaw(480px) {
            font-size: 16px !important;
            line-height: 24px !important;
            max-width: 280px !important;
        }
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .left {
            width: 289px;
        }
        .right {
            width: calc(100% - 320px);
            @include mediaw(992px) {
                width: 100%;
                margin-top: 55px;
            }
        }
    }
    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
    &__parent-element {
        position: relative;
        &::before {
            content: " ";
            position: absolute;
            display: block;
            width: 40px;
            height: 40px;
            background-color: rgba(255,255,255,.2);
            border-radius: 50%;
            left: 155px;
            top: -172px;
            @include mediaw(992px) {
                left: 50%;
                transform: translateX(-50%);
                top: -110px;
            }
        }
        &::after {
            content: " ";
            position: absolute;
            left: 163px;
            top: -164px;
            display: block;
            width: 25px;
            height: 25px;
            background-color: #fff;
            border-radius: 50%;
            @include mediaw(992px) {
                left: 50%;
                transform: translateX(-50%);
                top: -103px;
            }
        }
        .googoo-kyiv__slider-advantages {
            position: absolute;
            left: -113px;
            top: -134px;
            width: 200px;
            padding-bottom: 15px;
            border-bottom: 1px solid #fff;
            font-family: $fontMyriadRegular;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            @include mediaw(992px) {
                left: 50%;
                transform: translateX(-50%);
                top: -35px;
                border-bottom: none;
                text-align: center;
            }
            &::after {
                content: " ";
                position: absolute;
                display: block;
                right: -103px;
                bottom: 41px;
                width: 120px;
                height: 1px;
                background-color: #fff;
                transform: rotate(-44deg);
                @include mediaw(992px) {
                    transform: initial;
                    height: 60px;
                    width: 1px;
                    left: 50%;   
                    top: -60px;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .slick-dots {
        width: 400px;
        @include mediaw(992px) {
            width: 100%;
            bottom: -90px;
        }
        li {
            button {
                &::before {
                    font-size: 10px;
                    color: #fff;
                    opacity: 0.8;
                    transition: color .15s linear, font-size .15s linear;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        font-size: 15px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.smile {
    padding: 100px 0 110px;
    background-image: linear-gradient(to bottom, #fab419, #e8a81b 77%, #e6a619 86%, #f8b31c);
    color: #fff;
    text-align: center;
    @include mediaw(480px) {
        padding: 60px 0 20px;
    }
    &__description {
        margin: 0 auto;
    }
    .image-block {
        margin: 75px auto 0;
        max-width: 580px;
        position: relative;
        @include mediaw(576px) {
            margin: 130px auto 0;
        }
        img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
        }
        .smile__advantages1, .smile__advantages2 {
            position: absolute;
            width: 200px;
            padding-bottom: 15px;
            border-bottom: 1px solid #fff;
            text-align: left;
            font-family: $fontMyriadRegular;
            font-size: 14px;
            line-height: 18px;
        }
        .smile__advantages1 {
            bottom: 90px;
            left: -150px;
            @include mediaw(992px) {
                text-align: center;
                top: -50px;
                bottom: initial;
                left: calc(50% - 100px);
                border-bottom: none;
            }
            @include mediaw(576px) {
                top: -75px;
            }
            @include mediaw(425px) {
                top: -85px;
            }
            &::after {
                content: " ";
                position: absolute;
                display: block;
                right: -103px;
                bottom: 41px;
                width: 120px;
                height: 1px;
                background-color: #fff;
                transform: rotate(-44deg);
                @include mediaw(992px) {
                    width: 1px;
                    height: 60px;
                    right: calc(50% - 0.5px);
                    bottom: -70px;
                    transform: none;
                }
            }
            p {
                position: relative;
                &::after {
                    content: " ";
                    position: absolute;
                    bottom: 57px;
                    right: -98px;
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-color: rgba(255,255,255,1);
                    border-radius: 50%;
                    @include mediaw(992px) {
                        bottom: -97px;
                        right: 88px;
                    }
                }
                &::before {
                    content: " ";
                    position: absolute;
                    bottom: 50px;
                    right: -105px;
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(255,255,255,0.2);
                    border-radius: 50%;
                    @include mediaw(992px) {
                        bottom: -105px;
                        right: 81px;
                    }
                }
            }
        }
        .smile__advantages2 {
            top: 75px;
            right: -160px;
            @include mediaw(992px) {
                display: none;
            }
            &::before {
                content: " ";
                position: absolute;
                display: block;
                top: 75px;
                left: -102px;
                width: 120px;
                height: 1px;
                background-color: #fff;
                transform: rotate(-45deg);
            }
            p {
                position: relative;
                &::before {
                    content: " ";
                    position: absolute;
                    bottom: -120px;
                    left: -105px;
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: rgba(255,255,255,0.2);
                    border-radius: 50%;
                }
                &::after {
                    content: " ";
                    position: absolute;
                    bottom: -113px;
                    left: -97px;
                    display: block;
                    width: 25px;
                    height: 25px;
                    background-color: rgba(255,255,255,1);
                    border-radius: 50%;
                }
            }
        }
    }
}

.chamomile {
    background-image: linear-gradient(to bottom, #01db7c, #00c770 75%, #07d179 88%, #01da7b);
    color: #fff;
    padding: 0 0 80px;
    @include mediaw(992px) {
        padding: 120px 0 100px;
    }
    @include mediaw(480px) {
        padding: 60px 0 70px;
    }
    &__description {
        @include mediaw(992px) {
            max-width: initial !important;
        }
        @include mediaw(480px) {
            margin-top: 20px !important;
        }
    }
    &__row {
        display: flex;
        align-items: center;
        position: relative;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
        .slider-buttons {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -50px;
            display: flex;
            @include mediaw(768px) {
                width: 100%;
            }
            @include mediaw(576px) {
                flex-wrap: wrap;
                justify-content: center;
            }
            &::before {
                content: " ";
                position: absolute;
                display: block;
                width: 40px;
                height: 40px;
                background-color: rgba(255,255,255,.2);
                border-radius: 50%;
                left: 41px;
                top: -172px;
                @include mediaw(992px) {
                    left: -22px;
                }
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                position: absolute;
                left: 49px;
                top: -164px;
                display: block;
                width: 25px;
                height: 25px;
                background-color: #fff;
                border-radius: 50%;
                @include mediaw(992px) {
                    left: -14px;
                }
                @include mediaw(768px) {
                    display: none;
                }
            }
            .chamomile__slider-advantages {
                position: absolute;
                left: -225px;
                top: -120px;
                width: 200px;
                padding-bottom: 15px;
                border-bottom: 1px solid #fff;
                font-family: $fontMyriadRegular;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                @include mediaw(992px) {
                    left: -290px;
                }
                @include mediaw(768px) {
                    border-bottom: none;
                    padding-bottom: 0;
                    position: relative;
                    top: -40px;
                    right: initial;
                    bottom: initial;
                    left: initial;
                }
                @include mediaw(576px) {
                    width: 100%;
                    padding-right: 130px;
                    top: -5px;
                }
                &::after {
                    content: " ";
                    position: absolute;
                    display: block;
                    right: -103px;
                    bottom: 41px;
                    width: 120px;
                    height: 1px;
                    background-color: #fff;
                    transform: rotate(-44deg);
                    @include mediaw(768px) {
                        display: none;
                    }
                }
            }
            .chamomile-prev, .chamomile-next {
                width: 40px;
                height: 40px;
                background-color: #248C5E;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50%;
                border-radius: 50%;
                cursor: pointer;
                transition: background-color .15s linear;
                &:hover {
                    background-color: darken(#248C5E, 10%);
                }
            }
            .chamomile-prev {
                background-image: url(../img/left-arrow-white.svg);
                margin-right: 30px;
            }
            .chamomile-next {
                background-image: url(../img/right-arrow-white.svg);
            }
        }
        .left {
            width: 360px;
            @include mediaw(992px) {
                width: 100%;
            }
        }
        .right {
            width: calc(100% - 300px);
            .chamomile__slider {
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            @include mediaw(992px) {
                width: 100%;
            }
        }
    }
    .slick-dots {
        width: 400px;
        bottom: -65px;
        @include mediaw(992px) {
            width: 100%;
        }
        @include mediaw(576px) {
            bottom: -90px;
        }
        li {
            button {
                &::before {
                    font-size: 10px;
                    color: #fff;
                    opacity: 0.8;
                    transition: color .15s linear, font-size .15s linear;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        font-size: 15px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.about {
    padding: 180px 0 215px;
    background-color: #fff;
    @include mediaw(480px) {
        padding: 60px 0 55px;
    }
    &__title {
        text-align: center;
    }
    &__row {
        margin-top: 160px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaw(480px) {
            margin-top: 40px;
        }
    }
    &__col {
        width: calc(25% - 25px);
        @include mediaw(992px) {
            width: calc(50% - 25px);
        }
        @include mediaw(480px) {
            width: calc(50% - 5px);
        }
        &:nth-child(3) {
            @include mediaw(992px) {
                margin-top: 20px;
            }
        }
        &:last-child {
            @include mediaw(992px) {
                margin-top: 40px;
            }
        }
        .image {
            width: 190px;
            max-width: 100%;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
            }
        }
        p {
            margin-top: 40px;
            text-align: center;
            font-family: $fontMyriadRegular;
            font-size: 16px;
            line-height: 24px;
            color: #212121;
            @include mediaw(480px) {
                margin-top: 15px;
            }
        }
    }
}

.bottles {
    background-image: linear-gradient(to bottom, #ffdc5b, #f1c82f 83%, #edc223);
    &__row {
        margin-top: 90px;
        display: flex;
        flex-wrap: wrap;
        max-width: 702px;
        @include mediaw(768px) {
            justify-content: center;
        }
        @include mediaw(480px) {
            justify-content: space-between;
            margin-top: 30px;
        }
    }
    &__item {
        margin-right: 30px;
        @include mediaw(992px) {
            margin-right: 26px;
        }
        @include mediaw(768px) {
            margin-right: 0;
        }
        @include mediaw(480px) {
            width: calc(50% - 5px);
        }
        &:nth-child(2n) {
            @include mediaw(768px) {
                margin-left: 10px;
            }
            @include mediaw(480px) {
                margin-right: 0;
            }
        }
        .info {
            display: flex;
            justify-content: space-between;
            font-family: $fontMyriadRegular;
            font-size: 18px;
            line-height: 24px;
            .capacity {
                color: #909090;
                @include mediaw(480px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .name {
                color: #fff;
                @include mediaw(480px) {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        .images {
            margin-top: 15px;
            position: relative;
            .bottles__prev, .bottles__next {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 8px;
                height: 15px;
                background-size: cover;
                z-index: 1;
                cursor: pointer;
                background-color: transparent;
                @include mediaw(480px) {
                    width: 6px;
                    height: 11.3px;
                }
            }
            .bottles__prev {
                background-image: url(../img/arrow-left.svg);
                left: 15px;
                @include mediaw(480px) {
                    left: 10px;
                }
            }
            .bottles__next {
                background-image: url(../img/arrow-right.svg);
                right: 15px;
                @include mediaw(480px) {
                    right: 10px;
                }
            }
            .bottles__slider {
                width: 204px;
                height: 200px;
                opacity: 0.9;
                border-radius: 30px;
                background-color: #ffffff;
                padding: 15px 30px;
                // display: flex;
                // align-items: center;
                @include mediaw(480px) {
                    width: 100%;
                    min-height: 160px;
                    padding: 10px 20px;
                }
                .slick-track {
                    // width: 100% !important;
                    display: flex;
                    align-items: center;
                }
                img {
                    display: block;
                    // width: 100% !important;
                    max-width: 100%;
                    margin: auto;
                }
            }
            .slick-dots {
                bottom: 9px;
                left: 0;
                li {
                    height: 10px;
                    width: 10px;
                    margin: 0 5px;
                    &.slick-active {
                        button::before {
                            color: #01db7c;
                            opacity: 1;
                        }
                    }
                    button {
                        height: 10px;
                        width: 10px;
                        padding: 0;
                        &::before {
                            width: 10px;
                            height: 10px;
                            font-size: 5px;
                            line-height: 10px;
                            color: #01db7c;
                            opacity: .15;
                        }
                    }
                }
            }
        }
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            margin-top: 8px;
            @include mediaw(768px) {
                margin-top: 0;
            }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            @include mediaw(768px) {
                // margin-top: 20px;
            }
        }
        
    }
    &-overlay {
        padding: 100px 0 200px;
        background-image: url(../img/bottles-bg.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 185px) 260px;
        @include mediaw(1666px) {
            background-position: right 260px;
        }
        @include mediaw(992px) {
            background: none;
        }
        @include mediaw(768px) {
            padding: 60px 0 30px;
        }
    }
    &__slider-img-wrap{
        
    }
}

.eco {
    padding: 100px 0 85px;
    background-image: url(../img/eco-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    @include mediaw(768px) {
        padding: 60px 0 60px;
    }
    &__row {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
    }
    &__col {
        width: calc(50% - 15px);
        border-radius: 10px;
        background-color: rgba(255,255,255,0.5);
        padding: 55px 40px;
        @include mediaw(768px) {
            width: 100%;
        }
        @include mediaw(480px) {
            padding: 30px 20px;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 300px;
            margin: 0 auto;
        }
        .name {
            margin-top: 40px;
            font-family: $fontComfortaaBold;
            font-size: 24px;
            line-height: 27px;
            color: #242424;
            @include mediaw(480px) {
                margin-top: 30px;
            }
        }
        .description {
            margin-top: 20px;
            font-family: $fontMyriadRegular;
            font-size: 16px;
            line-height: 24px;
            @include mediaw(480px) {
                margin-top: 20px;
            }
        }
        &:last-child {
            @include mediaw(768px) {
                margin-top: 30px;
            }
        }
    }
}

.hygiene {
    padding: 100px 0 120px;
    background-color: #fff;
    @include mediaw(480px) {
        padding: 60px 0;
    }
    &__title {
        text-align: center;
        position: relative;
        &::before {
            content: " ";
            display: block;
            width: 18px;
            height: 18px;
            background-color: #6414dd;
            position: absolute;
            top: 2px;
            right: 129px;
            border-radius: 50%;
            @include mediaw(768px) {
                display: none;
            }
        }
        &::after {
            content: " ";
            display: block;
            width: 17px;
            height: 17px;
            background-color: #00d779;
            position: absolute;
            bottom: -19px;
            right: 268px;
            border-radius: 50%;
            @include mediaw(768px) {
                display: none;
            }
        }
    }
    &__row {
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @include mediaw(480px) {
            margin-top: 40px;
        }
        &::before {
            content: " ";
            display: block;
            width: 18px;
            height: 18px;
            background-color: #f6cf3f;
            position: absolute;
            top: -44px;
            left: -18px;
            border-radius: 50%;
            @include mediaw(768px) {
                display: none;
            }
        }
        &::after {
            content: " ";
            display: block;
            width: 17px;
            height: 17px;
            background-color: #00d779;
            position: absolute;
            top: -89px;
            right: -240px;
            border-radius: 50%;
            @include mediaw(768px) {
                display: none;
            }
        }
    }
    &__col {
        height: 300px;
        background-repeat: no-repeat;
        transition: background-position .15s linear;
        @include mediaw(768px) {
            border-radius: 10px !important;
        }
        &:not(:first-child) {
            @include mediaw(768px) {
                margin-top: 20px;
            }
        }
        &:nth-child(2) {
            margin-top: 0;
            @include mediaw(768px) {
                margin-top: 20px;
            }
        }
        &:not(:last-child) {
            width: 50%;
            @include mediaw(768px) {
                width: 100%;
            }
        }
        &:first-child {
            border-radius: 10px 0 0 0;
            background-color: #f6cf3f;
            background-image: url(../img/hygiene1.png);
            background-position: 190px 50px;
            @include mediaw(992px) {
                background-position: 140px 50px;
            }
            @include mediaw(768px) {
                background-position: 140px calc(100% - -65px);
                background-size: 80%;
            }
            &:hover {
                background-position: 170px 30px;
                @include mediaw(992px) {
                    background-position: 140px 50px;
                }
                @include mediaw(768px) {
                    background-position: 140px calc(100% - -65px);
                    background-size: 80%;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 17px;
                height: 17px;
                background-color: #00d779;
                position: absolute;
                top: 77px;
                left: -234px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 35px;
                height: 35px;
                background-color: #eabb64;
                position: absolute;
                bottom: -49px;
                left: -217px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
        }
        &:nth-child(2) {
            border-radius: 0 10px 0 0;
            background-color: rgba(102, 19, 226, .16);
            background-image: url(../img/hygiene2.png);
            background-position: 230px 30px;
            @include mediaw(992px) {
                background-position: 155px 30px;
            }
            @include mediaw(768px) {
                background-position: 140px calc(100% - -65px);
                background-size: 80%;
            }
            &:hover {
                background-position: 210px 10px;
                @include mediaw(992px) {
                    background-position: 155px 30px;
                }
                @include mediaw(768px) {
                    background-position: 140px calc(100% - -65px);
                    background-size: 80%;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 18px;
                height: 18px;
                background-color: #f6cf3f;
                position: absolute;
                top: -7px;
                right: -39px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 35px;
                height: 35px;
                background-color: #e6d8fa;
                position: absolute;
                bottom: -18px;
                right: -196px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
        }
        &:nth-child(3) {
            background-color: #00d779;
            background-image: url(../img/hygiene3.png);
            background-position: 225px 30px;
            @include mediaw(992px) {
                background-position: 200px 30px;
            }
            @include mediaw(768px) {
                background-position: 140px calc(100% - -65px);
                background-size: 80%;
            }
            &:hover {
                background-position: 205px 10px;
                @include mediaw(992px) {
                    background-position: 200px 30px;
                }
                @include mediaw(768px) {
                    background-position: 140px calc(100% - -65px);
                    background-size: 80%;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 35px;
                height: 35px;
                background-color: #e6d8fa;
                position: absolute;
                bottom: -51px;
                left: -360px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 17px;
                height: 17px;
                background-color: #00d779;
                position: absolute;
                bottom: -35px;
                left: -127px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
        }
        &:nth-child(4) {
            background-color: #f6a93f;
            background-image: url(../img/hygiene4.png);
            background-position: 320px 15px;
            @include mediaw(992px) {
                background-position: 190px 35px;
            }
            @include mediaw(768px) {
                background-position: 140px calc(100% - -65px);
                background-size: 80%;
            }
            &:hover {
                background-position: 300px -5px;
                @include mediaw(992px) {
                    background-position: 190px 35px;
                }
                @include mediaw(768px) {
                    background-position: 140px calc(100% - -65px);
                    background-size: 80%;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 17px;
                height: 17px;
                background-color: #00d779;
                position: absolute;
                bottom: 116px;
                right: -94px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 35px;
                height: 35px;
                background-color: #f6cf3f;
                position: absolute;
                bottom: -51px;
                right: -231px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
        }
        &:last-child {
            border-radius: 0 0 10px 10px;
            width: 100%;
            background-color: #fdea54;
            background-image: url(../img/hygiene5.png);
            background-position: 470px 15px;
            @include mediaw(992px) {
                background-position: 300px 15px;
            }
            @include mediaw(768px) {
                background-position: center calc(100% - -65px);
                background-size: 80%;
            }
            &:hover {
                background-position: 470px -5px;
                @include mediaw(992px) {
                    background-position: 300px 15px;
                }
                @include mediaw(768px) {
                    background-position: center calc(100% - -65px);
                    background-size: 80%;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 35px;
                height: 35px;
                background-color: #f6cf3f;
                position: absolute;
                bottom: -103px;
                left: -90px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
            &::after {
                content: " ";
                display: block;
                width: 17px;
                height: 17px;
                background-color: #00d779;
                position: absolute;
                bottom: 20px;
                right: -127px;
                border-radius: 50%;
                @include mediaw(768px) {
                    display: none;
                }
            }
        }
    }
    &__item {
        padding: 50px 40px;
        color: #404040;
        @include mediaw(992px) {
            padding: 30px 20px;
        }
        .title {
            font-family: $fontComfortaaBold;
            font-size: 24px;
            line-height: 27px;
        }
        .description {
            margin-top: 20px;
            font-family: $fontMyriadRegular;
            font-size: 16px;
            line-height: 24px;
            max-width: 270px;
        }
    }
}

.plate {
    padding: 100px 0 205px;
    background-image: linear-gradient(to bottom, #a6c4eb, #7ca1d6 83%, #9dbcea);
    // @include mediaw(576px) {
    //     background-image: linear-gradient(to bottom, #6613e2, #532598 83%, #6613e2);
    // }
    @include mediaw(480px) {
        padding: 60px 0 105px;
    }
    &__title {
        color: #fff;
        text-align: center;
    }
    &__description {
        color: #fff;
        margin-left: auto;
        margin-right: auto;
    }
    .image {
        margin: 145px auto 0;
        position: relative;
        max-width: 877px;
        @include mediaw(480px) {
            margin: 108px auto 0;
        }
        .capacity {
            position: absolute;
            top: 73px;
            left: 35px;
            width: 120px;
            height: 120px;
            @include mediaw(576px) {
                width: 96px;
                height: 96px;
                top: -40px;
                left: calc(50% - 43px);
            }
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}

.contact {
    padding: 105px 0;
    background-image: url("../img/contact-bg.png");
    background-repeat: no-repeat;
    background-position: 280px bottom;
    @include mediaw(1450px) {
        background-position: left bottom;
    }
    @include mediaw(992px) {
        background-image: none;
    }
    @include mediaw(480px) {
        padding: 60px 0;
    }
    .form {
        width: 555px;
        margin-left: auto;
        padding: 30px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.16);
        @include mediaw(992px) {
            width: 100%;
        }
        &__title {
            color: #6613e2;
            font-family: $fontComfortaaBold;
            font-size: 38px;
            line-height: 42px;
            text-align: center;
            margin-top: 10px;
            letter-spacing: 2px;
            @include mediaw(480px) {
                font-size: 28px;
                line-height: 32px;
            }
        }
        &__first-row {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            @include mediaw(576px) {
                flex-wrap: wrap;
            }
        }
        &__second-row {
            display: flex;
            justify-content: space-between;
            @include mediaw(576px) {
                flex-wrap: wrap;
            }
        }
        #your-first-name-field, #your-second-name-field, #your-email, #your-phone {
            width: calc(50% - 15px);
            margin-top: 10px;
            @include mediaw(576px) {
                width: 100%;
            }
        }
        #your-company {
            margin-top: 10px;
        }
        #your-message {
            margin-top: 30px;
            label {
                color: #606060;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
            }
            textarea {
                display: block;
                margin-top: 20px;
                width: 100%;
                height: 100px;
                border: solid 1px #606060;
                padding: 5px;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
                color: #606060;
                resize: none;
            }
        }
        #submit {
            position: relative;
            .ajax-loader {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        input:not([type="submit"]) {
            display: block;
            width: 100%;
            border-bottom: 1px solid #606060;
            border-radius: 0 !important;
            padding: 20px 0;
            font-family: $fontMyriadRegular;
            font-size: 18px;
            line-height: 24px;
            color: #606060;
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #606060;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: #606060;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: #606060;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: #606060;
                font-family: $fontMyriadRegular;
                font-size: 18px;
                line-height: 24px;
            }
        }
        input[type="submit"] {
            display: block;
            width: 220px;
            height: 60px;
            border-radius: 5px;
            -webkit-appearance: none;
            background-color: #00db7c;
            margin: 30px auto 0;
            font-family: $fontComfortaaBold;
            font-size: 20px;
            line-height: 23px;
            color: #fff;
            cursor: pointer;
            transition: background-color .15s linear;
            &:hover {
                background-color: darken(#00db7c, 10%);
            }
        }
    }
    .wpcf7-not-valid-tip, .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
       font-family: $fontMyriadRegular;
       margin-top: 5px;
       font-size: 14px;
    }
    .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
        margin: 25px 0 0;
    }
    .wpcf7-validation-errors {
        border: 1px solid #f00;
    }
    .wpcf7-mail-sent-ok {
        border: 1px solid #008000;
    }
}

.image {
    margin:30px;
    cursor:zoom-in;
}

.popup {
    position: absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    display:none;
    text-align:center;
}

.popup_bg {
    background:rgba(0,0,0,0.5);
    position:fixed;
    z-index:1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
}


.popup_img {
    position: fixed;
    z-index:2;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-height: 90%;
    max-width: 100%;
    min-width: 50%;
}

.popup_img {
    pointer-events: none;
}