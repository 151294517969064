@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Myriad Pro Regular';
  src: url("../fonts/MyriadPro-Regular.eot");
  src: url("../fonts/MyriadPro-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/MyriadPro-Regular.woff") format("woff"), url("../fonts/MyriadPro-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Comfortaa Bold';
  src: url("../fonts/Comfortaa-Bold.eot");
  src: url("../fonts/Comfortaa-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Comfortaa-Bold.woff") format("woff"), url("../fonts/Comfortaa-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-image: linear-gradient(to left, #fed200, #ec3386);
  color: #fff; }
  .header__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Comfortaa Bold", Arial, sans-serif;
    font-size: 14px;
    line-height: 15px;
    color: #fff;
    padding: 35px 0; }
    @media only screen and (max-width: 830px) {
      .header__navigation::before {
        content: " ";
        transform: translateX(100%);
        transition: transform .15s linear;
        display: block;
        width: calc(100% + 30px);
        height: 100px;
        background-image: linear-gradient(271deg, #f7992f, #f26b56);
        position: fixed;
        top: 0;
        left: -15px;
        z-index: 2; } }
    @media only screen and (max-width: 830px) {
      .header__navigation.active::before {
        transform: translateX(0); } }
  .header__lang-and-menu--wrapper {
    width: 100%;
    padding: 0 40px; }
    @media only screen and (max-width: 992px) {
      .header__lang-and-menu--wrapper {
        padding: 0 20px; } }
    @media only screen and (max-width: 830px) {
      .header__lang-and-menu--wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;
        transform: translateX(100%);
        background-image: linear-gradient(271deg, #f7992f, #f26b56);
        transition: transform .15s linear;
        z-index: 1;
        padding: 135px 15px;
        display: block;
        height: auto;
        min-height: 100vh;
        max-height: 100vh;
        overflow: auto; } }
    @media only screen and (max-width: 830px) {
      .header__lang-and-menu--wrapper::-webkit-scrollbar {
        width: 0 !important; } }
    .header__lang-and-menu--wrapper.active {
      transform: translateX(0); }
  .header__lang-and-menu {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media only screen and (max-width: 830px) {
      .header__lang-and-menu {
        display: block;
        max-height: calc(100vh - 300px);
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none; } }
    @media only screen and (max-width: 830px) {
      .header__lang-and-menu::-webkit-scrollbar {
        width: 0 !important; } }
  .header__menu {
    display: flex;
    align-items: center; }
    @media only screen and (max-width: 830px) {
      .header__menu {
        padding-bottom: 150px; } }
    .header__menu ul {
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 830px) {
        .header__menu ul {
          align-items: unset;
          display: block;
          margin: 0 auto;
          font-size: 28px;
          line-height: 32px;
          text-align: center; } }
      .header__menu ul li {
        position: relative; }
        .header__menu ul li:not(:last-child) {
          margin-right: 40px; }
          @media only screen and (max-width: 830px) {
            .header__menu ul li:not(:last-child) {
              margin-right: 0;
              margin-bottom: 30px; } }
        @media only screen and (max-width: 830px) {
          .header__menu ul li {
            position: static; } }
        .header__menu ul li.certificates-btn {
          position: relative; }
          .header__menu ul li.certificates-btn .certificates {
            position: absolute;
            top: 45px;
            left: -746px;
            width: 1140px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: #f0f0f0;
            border-radius: 15px;
            padding: 30px 80px 30px 40px;
            z-index: 1;
            color: #606060;
            font-family: "Myriad Pro Regular", Arial, sans-serif;
            font-size: 16px;
            line-height: 24px;
            display: none; }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.certificates-btn .certificates::before {
                content: '';
                border: 15px solid transparent;
                border-bottom: 15px solid #f0f0f0;
                position: absolute;
                top: 32px;
                left: 50%;
                transform: translateX(-50%); } }
            .header__menu ul li.certificates-btn .certificates::after {
              content: '';
              position: absolute;
              top: -30px;
              left: calc(50% - -235px);
              transform: translateX(-50%);
              border: 15px solid transparent;
              border-bottom: 15px solid #f0f0f0; }
              @media only screen and (max-width: 1199px) {
                .header__menu ul li.certificates-btn .certificates::after {
                  left: calc(50% - -105px); } }
              @media only screen and (max-width: 992px) {
                .header__menu ul li.certificates-btn .certificates::after {
                  left: calc(50% - -55px); } }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.certificates-btn .certificates::after {
                  display: none; } }
            @media only screen and (max-width: 1199px) {
              .header__menu ul li.certificates-btn .certificates {
                padding: 20px;
                left: -450px;
                width: 800px; } }
            @media only screen and (max-width: 992px) {
              .header__menu ul li.certificates-btn .certificates {
                left: -400px; } }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.certificates-btn .certificates {
                position: static;
                margin-top: 30px;
                padding: 30px 50px 20px;
                opacity: 1;
                width: initial;
                overflow: auto;
                overflow: -moz-scrollbars-none;
                -ms-overflow-style: none;
                height: 400px; } }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.certificates-btn .certificates::-webkit-scrollbar {
                width: 0 !important; } }
            .header__menu ul li.certificates-btn .certificates__title {
              font-family: "Comfortaa Bold", Arial, sans-serif;
              font-size: 24px;
              line-height: 27px;
              color: #242424;
              padding-top: 40px; }
            .header__menu ul li.certificates-btn .certificates__description {
              margin-top: 20px;
              font-size: 16px;
              line-height: 24px; }
            .header__menu ul li.certificates-btn .certificates__row {
              display: flex;
              justify-content: space-between; }
            .header__menu ul li.certificates-btn .certificates__col:first-child {
              width: 200px; }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.certificates-btn .certificates__col:first-child {
                  display: none; } }
            .header__menu ul li.certificates-btn .certificates__col:nth-child(2) {
              width: 320px; }
              .header__menu ul li.certificates-btn .certificates__col:nth-child(2) .certificate__current-image {
                display: block;
                margin: 0 auto;
                max-width: 320px;
                max-height: 450px; }
                @media only screen and (max-width: 992px) {
                  .header__menu ul li.certificates-btn .certificates__col:nth-child(2) .certificate__current-image {
                    max-width: 285px; } }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.certificates-btn .certificates__col:nth-child(2) {
                  display: none; } }
            .header__menu ul li.certificates-btn .certificates__col:last-child {
              width: 330px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between; }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.certificates-btn .certificates__col:last-child {
                  width: 100%; } }
              .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item {
                width: calc(50% - 10px);
                display: flex;
                justify-content: center;
                align-items: center;
                align-items: flex-start; }
                @media only screen and (max-width: 830px) {
                  .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item {
                    display: block;
                    width: 100%; } }
                .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item img {
                  display: block;
                  max-width: 100%;
                  margin: 0 auto;
                  width: 166px;
                  height: 232px; }
                .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item p {
                  display: none; }
                  @media only screen and (max-width: 830px) {
                    .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item p {
                      display: block;
                      text-align: center; } }
                .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item:nth-child(3) {
                  margin-top: 15px; }
                .header__menu ul li.certificates-btn .certificates__col:last-child .certificate__item:last-child {
                  margin-top: 15px; }
            .header__menu ul li.certificates-btn .certificates .certificate__current {
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center; }
            .header__menu ul li.certificates-btn .certificates .certificate__item {
              position: relative;
              cursor: pointer; }
              .header__menu ul li.certificates-btn .certificates .certificate__item .watch {
                opacity: 0;
                transition: opacity .15s linear;
                display: block;
                width: 65px;
                height: 65px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background-image: url(../img/eye.svg);
                background-size: 50%;
                background-repeat: no-repeat;
                background-position: center center;
                box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
                background-color: #f15b65;
                border-radius: 50%; }
                @media only screen and (max-width: 830px) {
                  .header__menu ul li.certificates-btn .certificates .certificate__item .watch {
                    display: none; } }
              .header__menu ul li.certificates-btn .certificates .certificate__item:hover .watch {
                opacity: 1; }
        .header__menu ul li.dropdown {
          padding-right: 23px;
          background-image: url(../img/arrow-down.svg);
          background-position: right 7px;
          background-size: 15px 6.5px;
          background-repeat: no-repeat; }
          @media only screen and (max-width: 992px) {
            .header__menu ul li.dropdown {
              padding-right: 15px; } }
          @media only screen and (max-width: 830px) {
            .header__menu ul li.dropdown {
              background-image: none;
              padding-right: 0; } }
          @media only screen and (max-width: 830px) {
            .header__menu ul li.dropdown span {
              display: inline-block;
              width: 17px;
              height: 10px;
              margin: 0 0 2px 7px;
              background-image: url(../img/arrow-down.svg);
              background-repeat: no-repeat;
              background-size: cover; } }
          .header__menu ul li.dropdown ul.sub-menu {
            position: absolute;
            top: 20px;
            left: 50%;
            width: 200px;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            font-family: "Comfortaa Bold",Arial,sans-serif;
            display: block;
            padding: 10px 10px 25px;
            border-radius: 5px;
            font-size: 14px;
            line-height: 15px;
            color: #000;
            background-color: #fff;
            display: none;
            z-index: 1; }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.dropdown ul.sub-menu {
                opacity: 1;
                transform: none;
                margin-top: 40px;
                display: none;
                background-color: transparent;
                color: #fff;
                width: 100%;
                text-align: left;
                font-size: 24px;
                line-height: 27px; } }
            .header__menu ul li.dropdown ul.sub-menu::before {
              content: " ";
              position: absolute;
              top: -4px;
              left: calc(50% - 6px);
              border-radius: 2px;
              width: 12px;
              height: 12px;
              transform: rotate(45deg);
              background-color: #fff; }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.dropdown ul.sub-menu::before {
                  display: none; } }
            .header__menu ul li.dropdown ul.sub-menu li:first-child a {
              margin-top: 0; }
            .header__menu ul li.dropdown ul.sub-menu li:last-child a {
              margin-bottom: 0; }
            .header__menu ul li.dropdown ul.sub-menu li a {
              display: inline-block;
              margin: 3px 0; }
              .header__menu ul li.dropdown ul.sub-menu li a:hover {
                text-decoration: underline; }
              @media only screen and (max-width: 830px) {
                .header__menu ul li.dropdown ul.sub-menu li a {
                  margin: 0; } }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.dropdown ul.sub-menu li:not(:first-child) {
                margin-top: 30px; } }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.dropdown ul.sub-menu li:not(:last-child) {
                margin-bottom: 0; } }
            @media only screen and (max-width: 830px) {
              .header__menu ul li.dropdown ul.sub-menu {
                position: static; } }
  .header__language-switcher {
    cursor: pointer;
    position: relative;
    background-image: url(../img/arrow-down.svg);
    background-position: right 7px;
    background-size: 11.4px 6.5px;
    background-repeat: no-repeat;
    padding-right: 21.4px; }
    @media only screen and (max-width: 830px) {
      .header__language-switcher {
        background: none;
        position: static;
        padding-right: 0;
        margin-bottom: 25px; } }
    @media only screen and (max-height: 600px) {
      .header__language-switcher {
        margin-top: 60px; } }
    .header__language-switcher:hover .languages {
      opacity: 1; }
    @media only screen and (max-width: 830px) {
      .header__language-switcher span {
        display: none; } }
    .header__language-switcher .languages {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Comfortaa Bold", Arial, sans-serif;
      display: block;
      padding: 10px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 15px;
      color: #000;
      background-color: #fff;
      opacity: 0;
      transition: opacity .3s linear; }
      @media only screen and (max-width: 830px) {
        .header__language-switcher .languages {
          position: static;
          opacity: 1;
          padding: 0;
          border-radius: 0;
          transition: none;
          background: none;
          display: flex;
          transform: none;
          justify-content: center; } }
      @media only screen and (max-width: 830px) {
        .header__language-switcher .languages li {
          font-size: 18px;
          line-height: 32px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 2px solid rgba(112, 112, 112, 0.3);
          color: #fff; } }
      @media only screen and (max-width: 830px) {
        .header__language-switcher .languages li:first-child {
          margin-right: 15px; } }
      .header__language-switcher .languages .current-lang {
        display: none; }
        @media only screen and (max-width: 830px) {
          .header__language-switcher .languages .current-lang {
            display: flex;
            background-color: rgba(33, 33, 33, 0.3);
            border: none; } }
      .header__language-switcher .languages::before {
        content: " ";
        position: absolute;
        top: -4px;
        left: 12px;
        border-radius: 2px;
        width: 12px;
        height: 12px;
        transform: rotate(45deg);
        background-color: #fff; }
        @media only screen and (max-width: 830px) {
          .header__language-switcher .languages::before {
            display: none; } }
  .header__contact-btn {
    width: 170px;
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606060;
    position: relative;
    z-index: 2; }
    @media only screen and (max-width: 480px) {
      .header__contact-btn {
        width: 138px; } }
  .header__logo {
    position: relative;
    z-index: 2; }
    @media only screen and (max-width: 480px) {
      .header__logo {
        max-width: 136px; } }
  .header__hamburger-btn {
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 777; }
    @media only screen and (max-width: 830px) {
      .header__hamburger-btn {
        display: flex; } }
    .header__hamburger-btn span {
      display: block;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      position: relative;
      height: 3px;
      border-radius: 3px;
      transition: .2s linear; }
      .header__hamburger-btn span.active {
        background-color: transparent; }
        .header__hamburger-btn span.active::before {
          transform: rotate(45deg);
          top: 0;
          left: 0; }
        .header__hamburger-btn span.active::after {
          transform: rotate(-45deg);
          top: 0;
          left: 0; }
      .header__hamburger-btn span::before {
        content: " ";
        display: block;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute;
        left: 0;
        top: -13px;
        height: 3px;
        border-radius: 3px;
        transition: .2s linear; }
      .header__hamburger-btn span::after {
        content: " ";
        border-radius: 3px;
        transition: .2s linear;
        height: 3px;
        left: 0;
        bottom: -13px;
        display: block;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        position: absolute; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.googoo-kyiv {
  background-image: linear-gradient(to left, #fed200, #ec3386);
  color: #fff;
  padding: 0 0 45px; }
  @media only screen and (max-width: 992px) {
    .googoo-kyiv {
      padding: 0 0 75px; } }
  @media only screen and (max-width: 480px) {
    .googoo-kyiv__title {
      font-size: 32px !important;
      line-height: 35px !important; } }
  @media only screen and (max-width: 480px) {
    .googoo-kyiv__description {
      font-size: 16px !important;
      line-height: 24px !important;
      max-width: 280px !important; } }
  .googoo-kyiv__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .googoo-kyiv__row .left {
      width: 289px; }
    .googoo-kyiv__row .right {
      width: calc(100% - 320px); }
      @media only screen and (max-width: 992px) {
        .googoo-kyiv__row .right {
          width: 100%;
          margin-top: 55px; } }
  .googoo-kyiv img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .googoo-kyiv__parent-element {
    position: relative; }
    .googoo-kyiv__parent-element::before {
      content: " ";
      position: absolute;
      display: block;
      width: 40px;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      left: 155px;
      top: -172px; }
      @media only screen and (max-width: 992px) {
        .googoo-kyiv__parent-element::before {
          left: 50%;
          transform: translateX(-50%);
          top: -110px; } }
    .googoo-kyiv__parent-element::after {
      content: " ";
      position: absolute;
      left: 163px;
      top: -164px;
      display: block;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border-radius: 50%; }
      @media only screen and (max-width: 992px) {
        .googoo-kyiv__parent-element::after {
          left: 50%;
          transform: translateX(-50%);
          top: -103px; } }
    .googoo-kyiv__parent-element .googoo-kyiv__slider-advantages {
      position: absolute;
      left: -113px;
      top: -134px;
      width: 200px;
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 14px;
      line-height: 18px;
      color: #fff; }
      @media only screen and (max-width: 992px) {
        .googoo-kyiv__parent-element .googoo-kyiv__slider-advantages {
          left: 50%;
          transform: translateX(-50%);
          top: -35px;
          border-bottom: none;
          text-align: center; } }
      .googoo-kyiv__parent-element .googoo-kyiv__slider-advantages::after {
        content: " ";
        position: absolute;
        display: block;
        right: -103px;
        bottom: 41px;
        width: 120px;
        height: 1px;
        background-color: #fff;
        transform: rotate(-44deg); }
        @media only screen and (max-width: 992px) {
          .googoo-kyiv__parent-element .googoo-kyiv__slider-advantages::after {
            transform: initial;
            height: 60px;
            width: 1px;
            left: 50%;
            top: -60px;
            transform: translateX(-50%); } }
  .googoo-kyiv .slick-dots {
    width: 400px; }
    @media only screen and (max-width: 992px) {
      .googoo-kyiv .slick-dots {
        width: 100%;
        bottom: -90px; } }
    .googoo-kyiv .slick-dots li button::before {
      font-size: 10px;
      color: #fff;
      opacity: 0.8;
      transition: color .15s linear, font-size .15s linear; }
    .googoo-kyiv .slick-dots li.slick-active button::before {
      font-size: 15px;
      opacity: 1; }

.smile {
  padding: 100px 0 110px;
  background-image: linear-gradient(to bottom, #fab419, #e8a81b 77%, #e6a619 86%, #f8b31c);
  color: #fff;
  text-align: center; }
  @media only screen and (max-width: 480px) {
    .smile {
      padding: 60px 0 20px; } }
  .smile__description {
    margin: 0 auto; }
  .smile .image-block {
    margin: 75px auto 0;
    max-width: 580px;
    position: relative; }
    @media only screen and (max-width: 576px) {
      .smile .image-block {
        margin: 130px auto 0; } }
    .smile .image-block img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
    .smile .image-block .smile__advantages1, .smile .image-block .smile__advantages2 {
      position: absolute;
      width: 200px;
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
      text-align: left;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 14px;
      line-height: 18px; }
    .smile .image-block .smile__advantages1 {
      bottom: 90px;
      left: -150px; }
      @media only screen and (max-width: 992px) {
        .smile .image-block .smile__advantages1 {
          text-align: center;
          top: -50px;
          bottom: initial;
          left: calc(50% - 100px);
          border-bottom: none; } }
      @media only screen and (max-width: 576px) {
        .smile .image-block .smile__advantages1 {
          top: -75px; } }
      @media only screen and (max-width: 425px) {
        .smile .image-block .smile__advantages1 {
          top: -85px; } }
      .smile .image-block .smile__advantages1::after {
        content: " ";
        position: absolute;
        display: block;
        right: -103px;
        bottom: 41px;
        width: 120px;
        height: 1px;
        background-color: #fff;
        transform: rotate(-44deg); }
        @media only screen and (max-width: 992px) {
          .smile .image-block .smile__advantages1::after {
            width: 1px;
            height: 60px;
            right: calc(50% - 0.5px);
            bottom: -70px;
            transform: none; } }
      .smile .image-block .smile__advantages1 p {
        position: relative; }
        .smile .image-block .smile__advantages1 p::after {
          content: " ";
          position: absolute;
          bottom: 57px;
          right: -98px;
          display: block;
          width: 25px;
          height: 25px;
          background-color: white;
          border-radius: 50%; }
          @media only screen and (max-width: 992px) {
            .smile .image-block .smile__advantages1 p::after {
              bottom: -97px;
              right: 88px; } }
        .smile .image-block .smile__advantages1 p::before {
          content: " ";
          position: absolute;
          bottom: 50px;
          right: -105px;
          display: block;
          width: 40px;
          height: 40px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 50%; }
          @media only screen and (max-width: 992px) {
            .smile .image-block .smile__advantages1 p::before {
              bottom: -105px;
              right: 81px; } }
    .smile .image-block .smile__advantages2 {
      top: 75px;
      right: -160px; }
      @media only screen and (max-width: 992px) {
        .smile .image-block .smile__advantages2 {
          display: none; } }
      .smile .image-block .smile__advantages2::before {
        content: " ";
        position: absolute;
        display: block;
        top: 75px;
        left: -102px;
        width: 120px;
        height: 1px;
        background-color: #fff;
        transform: rotate(-45deg); }
      .smile .image-block .smile__advantages2 p {
        position: relative; }
        .smile .image-block .smile__advantages2 p::before {
          content: " ";
          position: absolute;
          bottom: -120px;
          left: -105px;
          display: block;
          width: 40px;
          height: 40px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 50%; }
        .smile .image-block .smile__advantages2 p::after {
          content: " ";
          position: absolute;
          bottom: -113px;
          left: -97px;
          display: block;
          width: 25px;
          height: 25px;
          background-color: white;
          border-radius: 50%; }

.chamomile {
  background-image: linear-gradient(to bottom, #01db7c, #00c770 75%, #07d179 88%, #01da7b);
  color: #fff;
  padding: 0 0 80px; }
  @media only screen and (max-width: 992px) {
    .chamomile {
      padding: 120px 0 100px; } }
  @media only screen and (max-width: 480px) {
    .chamomile {
      padding: 60px 0 70px; } }
  @media only screen and (max-width: 992px) {
    .chamomile__description {
      max-width: initial !important; } }
  @media only screen and (max-width: 480px) {
    .chamomile__description {
      margin-top: 20px !important; } }
  .chamomile__row {
    display: flex;
    align-items: center;
    position: relative; }
    @media only screen and (max-width: 992px) {
      .chamomile__row {
        flex-wrap: wrap; } }
    .chamomile__row .slider-buttons {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -50px;
      display: flex; }
      @media only screen and (max-width: 768px) {
        .chamomile__row .slider-buttons {
          width: 100%; } }
      @media only screen and (max-width: 576px) {
        .chamomile__row .slider-buttons {
          flex-wrap: wrap;
          justify-content: center; } }
      .chamomile__row .slider-buttons::before {
        content: " ";
        position: absolute;
        display: block;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        left: 41px;
        top: -172px; }
        @media only screen and (max-width: 992px) {
          .chamomile__row .slider-buttons::before {
            left: -22px; } }
        @media only screen and (max-width: 768px) {
          .chamomile__row .slider-buttons::before {
            display: none; } }
      .chamomile__row .slider-buttons::after {
        content: " ";
        position: absolute;
        left: 49px;
        top: -164px;
        display: block;
        width: 25px;
        height: 25px;
        background-color: #fff;
        border-radius: 50%; }
        @media only screen and (max-width: 992px) {
          .chamomile__row .slider-buttons::after {
            left: -14px; } }
        @media only screen and (max-width: 768px) {
          .chamomile__row .slider-buttons::after {
            display: none; } }
      .chamomile__row .slider-buttons .chamomile__slider-advantages {
        position: absolute;
        left: -225px;
        top: -120px;
        width: 200px;
        padding-bottom: 15px;
        border-bottom: 1px solid #fff;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 14px;
        line-height: 18px;
        color: #fff; }
        @media only screen and (max-width: 992px) {
          .chamomile__row .slider-buttons .chamomile__slider-advantages {
            left: -290px; } }
        @media only screen and (max-width: 768px) {
          .chamomile__row .slider-buttons .chamomile__slider-advantages {
            border-bottom: none;
            padding-bottom: 0;
            position: relative;
            top: -40px;
            right: initial;
            bottom: initial;
            left: initial; } }
        @media only screen and (max-width: 576px) {
          .chamomile__row .slider-buttons .chamomile__slider-advantages {
            width: 100%;
            padding-right: 130px;
            top: -5px; } }
        .chamomile__row .slider-buttons .chamomile__slider-advantages::after {
          content: " ";
          position: absolute;
          display: block;
          right: -103px;
          bottom: 41px;
          width: 120px;
          height: 1px;
          background-color: #fff;
          transform: rotate(-44deg); }
          @media only screen and (max-width: 768px) {
            .chamomile__row .slider-buttons .chamomile__slider-advantages::after {
              display: none; } }
      .chamomile__row .slider-buttons .chamomile-prev, .chamomile__row .slider-buttons .chamomile-next {
        width: 40px;
        height: 40px;
        background-color: #248C5E;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50%;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color .15s linear; }
        .chamomile__row .slider-buttons .chamomile-prev:hover, .chamomile__row .slider-buttons .chamomile-next:hover {
          background-color: #1a6343; }
      .chamomile__row .slider-buttons .chamomile-prev {
        background-image: url(../img/left-arrow-white.svg);
        margin-right: 30px; }
      .chamomile__row .slider-buttons .chamomile-next {
        background-image: url(../img/right-arrow-white.svg); }
    .chamomile__row .left {
      width: 360px; }
      @media only screen and (max-width: 992px) {
        .chamomile__row .left {
          width: 100%; } }
    .chamomile__row .right {
      width: calc(100% - 300px); }
      .chamomile__row .right .chamomile__slider img {
        display: block;
        max-width: 100%;
        margin: 0 auto; }
      @media only screen and (max-width: 992px) {
        .chamomile__row .right {
          width: 100%; } }
  .chamomile .slick-dots {
    width: 400px;
    bottom: -65px; }
    @media only screen and (max-width: 992px) {
      .chamomile .slick-dots {
        width: 100%; } }
    @media only screen and (max-width: 576px) {
      .chamomile .slick-dots {
        bottom: -90px; } }
    .chamomile .slick-dots li button::before {
      font-size: 10px;
      color: #fff;
      opacity: 0.8;
      transition: color .15s linear, font-size .15s linear; }
    .chamomile .slick-dots li.slick-active button::before {
      font-size: 15px;
      opacity: 1; }

.about {
  padding: 180px 0 215px;
  background-color: #fff; }
  @media only screen and (max-width: 480px) {
    .about {
      padding: 60px 0 55px; } }
  .about__title {
    text-align: center; }
  .about__row {
    margin-top: 160px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    @media only screen and (max-width: 480px) {
      .about__row {
        margin-top: 40px; } }
  .about__col {
    width: calc(25% - 25px); }
    @media only screen and (max-width: 992px) {
      .about__col {
        width: calc(50% - 25px); } }
    @media only screen and (max-width: 480px) {
      .about__col {
        width: calc(50% - 5px); } }
    @media only screen and (max-width: 992px) {
      .about__col:nth-child(3) {
        margin-top: 20px; } }
    @media only screen and (max-width: 992px) {
      .about__col:last-child {
        margin-top: 40px; } }
    .about__col .image {
      width: 190px;
      max-width: 100%;
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto; }
      .about__col .image img {
        display: block;
        margin: 0 auto;
        max-width: 100%; }
    .about__col p {
      margin-top: 40px;
      text-align: center;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 16px;
      line-height: 24px;
      color: #212121; }
      @media only screen and (max-width: 480px) {
        .about__col p {
          margin-top: 15px; } }

.bottles {
  background-image: linear-gradient(to bottom, #ffdc5b, #f1c82f 83%, #edc223); }
  .bottles__row {
    margin-top: 90px;
    display: flex;
    flex-wrap: wrap;
    max-width: 702px; }
    @media only screen and (max-width: 768px) {
      .bottles__row {
        justify-content: center; } }
    @media only screen and (max-width: 480px) {
      .bottles__row {
        justify-content: space-between;
        margin-top: 30px; } }
  .bottles__item {
    margin-right: 30px; }
    @media only screen and (max-width: 992px) {
      .bottles__item {
        margin-right: 26px; } }
    @media only screen and (max-width: 768px) {
      .bottles__item {
        margin-right: 0; } }
    @media only screen and (max-width: 480px) {
      .bottles__item {
        width: calc(50% - 5px); } }
    @media only screen and (max-width: 768px) {
      .bottles__item:nth-child(2n) {
        margin-left: 10px; } }
    @media only screen and (max-width: 480px) {
      .bottles__item:nth-child(2n) {
        margin-right: 0; } }
    .bottles__item .info {
      display: flex;
      justify-content: space-between;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 18px;
      line-height: 24px; }
      .bottles__item .info .capacity {
        color: #909090; }
        @media only screen and (max-width: 480px) {
          .bottles__item .info .capacity {
            font-size: 14px;
            line-height: 16px; } }
      .bottles__item .info .name {
        color: #fff; }
        @media only screen and (max-width: 480px) {
          .bottles__item .info .name {
            font-size: 14px;
            line-height: 16px; } }
    .bottles__item .images {
      margin-top: 15px;
      position: relative; }
      .bottles__item .images .bottles__prev, .bottles__item .images .bottles__next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 15px;
        background-size: cover;
        z-index: 1;
        cursor: pointer;
        background-color: transparent; }
        @media only screen and (max-width: 480px) {
          .bottles__item .images .bottles__prev, .bottles__item .images .bottles__next {
            width: 6px;
            height: 11.3px; } }
      .bottles__item .images .bottles__prev {
        background-image: url(../img/arrow-left.svg);
        left: 15px; }
        @media only screen and (max-width: 480px) {
          .bottles__item .images .bottles__prev {
            left: 10px; } }
      .bottles__item .images .bottles__next {
        background-image: url(../img/arrow-right.svg);
        right: 15px; }
        @media only screen and (max-width: 480px) {
          .bottles__item .images .bottles__next {
            right: 10px; } }
      .bottles__item .images .bottles__slider {
        width: 204px;
        height: 200px;
        opacity: 0.9;
        border-radius: 30px;
        background-color: #ffffff;
        padding: 15px 30px; }
        @media only screen and (max-width: 480px) {
          .bottles__item .images .bottles__slider {
            width: 100%;
            min-height: 160px;
            padding: 10px 20px; } }
        .bottles__item .images .bottles__slider .slick-track {
          display: flex;
          align-items: center; }
        .bottles__item .images .bottles__slider img {
          display: block;
          max-width: 100%;
          margin: auto; }
      .bottles__item .images .slick-dots {
        bottom: 9px;
        left: 0; }
        .bottles__item .images .slick-dots li {
          height: 10px;
          width: 10px;
          margin: 0 5px; }
          .bottles__item .images .slick-dots li.slick-active button::before {
            color: #01db7c;
            opacity: 1; }
          .bottles__item .images .slick-dots li button {
            height: 10px;
            width: 10px;
            padding: 0; }
            .bottles__item .images .slick-dots li button::before {
              width: 10px;
              height: 10px;
              font-size: 5px;
              line-height: 10px;
              color: #01db7c;
              opacity: .15; }
    .bottles__item:nth-child(4), .bottles__item:nth-child(5), .bottles__item:nth-child(6) {
      margin-top: 8px; }
      @media only screen and (max-width: 768px) {
        .bottles__item:nth-child(4), .bottles__item:nth-child(5), .bottles__item:nth-child(6) {
          margin-top: 0; } }
  .bottles-overlay {
    padding: 100px 0 200px;
    background-image: url(../img/bottles-bg.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 185px) 260px; }
    @media only screen and (max-width: 1666px) {
      .bottles-overlay {
        background-position: right 260px; } }
    @media only screen and (max-width: 992px) {
      .bottles-overlay {
        background: none; } }
    @media only screen and (max-width: 768px) {
      .bottles-overlay {
        padding: 60px 0 30px; } }

.eco {
  padding: 100px 0 85px;
  background-image: url(../img/eco-bg.png);
  background-repeat: no-repeat;
  background-size: cover; }
  @media only screen and (max-width: 768px) {
    .eco {
      padding: 60px 0 60px; } }
  .eco__row {
    margin-top: 40px;
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .eco__row {
        flex-wrap: wrap; } }
  .eco__col {
    width: calc(50% - 15px);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 55px 40px; }
    @media only screen and (max-width: 768px) {
      .eco__col {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .eco__col {
        padding: 30px 20px; } }
    .eco__col img {
      display: block;
      max-width: 100%;
      max-height: 300px;
      margin: 0 auto; }
    .eco__col .name {
      margin-top: 40px;
      font-family: "Comfortaa Bold", Arial, sans-serif;
      font-size: 24px;
      line-height: 27px;
      color: #242424; }
      @media only screen and (max-width: 480px) {
        .eco__col .name {
          margin-top: 30px; } }
    .eco__col .description {
      margin-top: 20px;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 16px;
      line-height: 24px; }
      @media only screen and (max-width: 480px) {
        .eco__col .description {
          margin-top: 20px; } }
    @media only screen and (max-width: 768px) {
      .eco__col:last-child {
        margin-top: 30px; } }

.hygiene {
  padding: 100px 0 120px;
  background-color: #fff; }
  @media only screen and (max-width: 480px) {
    .hygiene {
      padding: 60px 0; } }
  .hygiene__title {
    text-align: center;
    position: relative; }
    .hygiene__title::before {
      content: " ";
      display: block;
      width: 18px;
      height: 18px;
      background-color: #6414dd;
      position: absolute;
      top: 2px;
      right: 129px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .hygiene__title::before {
          display: none; } }
    .hygiene__title::after {
      content: " ";
      display: block;
      width: 17px;
      height: 17px;
      background-color: #00d779;
      position: absolute;
      bottom: -19px;
      right: 268px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .hygiene__title::after {
          display: none; } }
  .hygiene__row {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    position: relative; }
    @media only screen and (max-width: 480px) {
      .hygiene__row {
        margin-top: 40px; } }
    .hygiene__row::before {
      content: " ";
      display: block;
      width: 18px;
      height: 18px;
      background-color: #f6cf3f;
      position: absolute;
      top: -44px;
      left: -18px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .hygiene__row::before {
          display: none; } }
    .hygiene__row::after {
      content: " ";
      display: block;
      width: 17px;
      height: 17px;
      background-color: #00d779;
      position: absolute;
      top: -89px;
      right: -240px;
      border-radius: 50%; }
      @media only screen and (max-width: 768px) {
        .hygiene__row::after {
          display: none; } }
  .hygiene__col {
    height: 300px;
    background-repeat: no-repeat;
    transition: background-position .15s linear; }
    @media only screen and (max-width: 768px) {
      .hygiene__col {
        border-radius: 10px !important; } }
    @media only screen and (max-width: 768px) {
      .hygiene__col:not(:first-child) {
        margin-top: 20px; } }
    .hygiene__col:nth-child(2) {
      margin-top: 0; }
      @media only screen and (max-width: 768px) {
        .hygiene__col:nth-child(2) {
          margin-top: 20px; } }
    .hygiene__col:not(:last-child) {
      width: 50%; }
      @media only screen and (max-width: 768px) {
        .hygiene__col:not(:last-child) {
          width: 100%; } }
    .hygiene__col:first-child {
      border-radius: 10px 0 0 0;
      background-color: #f6cf3f;
      background-image: url(../img/hygiene1.png);
      background-position: 190px 50px; }
      @media only screen and (max-width: 992px) {
        .hygiene__col:first-child {
          background-position: 140px 50px; } }
      @media only screen and (max-width: 768px) {
        .hygiene__col:first-child {
          background-position: 140px calc(100% - -65px);
          background-size: 80%; } }
      .hygiene__col:first-child:hover {
        background-position: 170px 30px; }
        @media only screen and (max-width: 992px) {
          .hygiene__col:first-child:hover {
            background-position: 140px 50px; } }
        @media only screen and (max-width: 768px) {
          .hygiene__col:first-child:hover {
            background-position: 140px calc(100% - -65px);
            background-size: 80%; } }
      .hygiene__col:first-child::before {
        content: " ";
        display: block;
        width: 17px;
        height: 17px;
        background-color: #00d779;
        position: absolute;
        top: 77px;
        left: -234px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:first-child::before {
            display: none; } }
      .hygiene__col:first-child::after {
        content: " ";
        display: block;
        width: 35px;
        height: 35px;
        background-color: #eabb64;
        position: absolute;
        bottom: -49px;
        left: -217px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:first-child::after {
            display: none; } }
    .hygiene__col:nth-child(2) {
      border-radius: 0 10px 0 0;
      background-color: rgba(102, 19, 226, 0.16);
      background-image: url(../img/hygiene2.png);
      background-position: 230px 30px; }
      @media only screen and (max-width: 992px) {
        .hygiene__col:nth-child(2) {
          background-position: 155px 30px; } }
      @media only screen and (max-width: 768px) {
        .hygiene__col:nth-child(2) {
          background-position: 140px calc(100% - -65px);
          background-size: 80%; } }
      .hygiene__col:nth-child(2):hover {
        background-position: 210px 10px; }
        @media only screen and (max-width: 992px) {
          .hygiene__col:nth-child(2):hover {
            background-position: 155px 30px; } }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(2):hover {
            background-position: 140px calc(100% - -65px);
            background-size: 80%; } }
      .hygiene__col:nth-child(2)::before {
        content: " ";
        display: block;
        width: 18px;
        height: 18px;
        background-color: #f6cf3f;
        position: absolute;
        top: -7px;
        right: -39px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(2)::before {
            display: none; } }
      .hygiene__col:nth-child(2)::after {
        content: " ";
        display: block;
        width: 35px;
        height: 35px;
        background-color: #e6d8fa;
        position: absolute;
        bottom: -18px;
        right: -196px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(2)::after {
            display: none; } }
    .hygiene__col:nth-child(3) {
      background-color: #00d779;
      background-image: url(../img/hygiene3.png);
      background-position: 225px 30px; }
      @media only screen and (max-width: 992px) {
        .hygiene__col:nth-child(3) {
          background-position: 200px 30px; } }
      @media only screen and (max-width: 768px) {
        .hygiene__col:nth-child(3) {
          background-position: 140px calc(100% - -65px);
          background-size: 80%; } }
      .hygiene__col:nth-child(3):hover {
        background-position: 205px 10px; }
        @media only screen and (max-width: 992px) {
          .hygiene__col:nth-child(3):hover {
            background-position: 200px 30px; } }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(3):hover {
            background-position: 140px calc(100% - -65px);
            background-size: 80%; } }
      .hygiene__col:nth-child(3)::before {
        content: " ";
        display: block;
        width: 35px;
        height: 35px;
        background-color: #e6d8fa;
        position: absolute;
        bottom: -51px;
        left: -360px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(3)::before {
            display: none; } }
      .hygiene__col:nth-child(3)::after {
        content: " ";
        display: block;
        width: 17px;
        height: 17px;
        background-color: #00d779;
        position: absolute;
        bottom: -35px;
        left: -127px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(3)::after {
            display: none; } }
    .hygiene__col:nth-child(4) {
      background-color: #f6a93f;
      background-image: url(../img/hygiene4.png);
      background-position: 320px 15px; }
      @media only screen and (max-width: 992px) {
        .hygiene__col:nth-child(4) {
          background-position: 190px 35px; } }
      @media only screen and (max-width: 768px) {
        .hygiene__col:nth-child(4) {
          background-position: 140px calc(100% - -65px);
          background-size: 80%; } }
      .hygiene__col:nth-child(4):hover {
        background-position: 300px -5px; }
        @media only screen and (max-width: 992px) {
          .hygiene__col:nth-child(4):hover {
            background-position: 190px 35px; } }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(4):hover {
            background-position: 140px calc(100% - -65px);
            background-size: 80%; } }
      .hygiene__col:nth-child(4)::before {
        content: " ";
        display: block;
        width: 17px;
        height: 17px;
        background-color: #00d779;
        position: absolute;
        bottom: 116px;
        right: -94px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(4)::before {
            display: none; } }
      .hygiene__col:nth-child(4)::after {
        content: " ";
        display: block;
        width: 35px;
        height: 35px;
        background-color: #f6cf3f;
        position: absolute;
        bottom: -51px;
        right: -231px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:nth-child(4)::after {
            display: none; } }
    .hygiene__col:last-child {
      border-radius: 0 0 10px 10px;
      width: 100%;
      background-color: #fdea54;
      background-image: url(../img/hygiene5.png);
      background-position: 470px 15px; }
      @media only screen and (max-width: 992px) {
        .hygiene__col:last-child {
          background-position: 300px 15px; } }
      @media only screen and (max-width: 768px) {
        .hygiene__col:last-child {
          background-position: center calc(100% - -65px);
          background-size: 80%; } }
      .hygiene__col:last-child:hover {
        background-position: 470px -5px; }
        @media only screen and (max-width: 992px) {
          .hygiene__col:last-child:hover {
            background-position: 300px 15px; } }
        @media only screen and (max-width: 768px) {
          .hygiene__col:last-child:hover {
            background-position: center calc(100% - -65px);
            background-size: 80%; } }
      .hygiene__col:last-child::before {
        content: " ";
        display: block;
        width: 35px;
        height: 35px;
        background-color: #f6cf3f;
        position: absolute;
        bottom: -103px;
        left: -90px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:last-child::before {
            display: none; } }
      .hygiene__col:last-child::after {
        content: " ";
        display: block;
        width: 17px;
        height: 17px;
        background-color: #00d779;
        position: absolute;
        bottom: 20px;
        right: -127px;
        border-radius: 50%; }
        @media only screen and (max-width: 768px) {
          .hygiene__col:last-child::after {
            display: none; } }
  .hygiene__item {
    padding: 50px 40px;
    color: #404040; }
    @media only screen and (max-width: 992px) {
      .hygiene__item {
        padding: 30px 20px; } }
    .hygiene__item .title {
      font-family: "Comfortaa Bold", Arial, sans-serif;
      font-size: 24px;
      line-height: 27px; }
    .hygiene__item .description {
      margin-top: 20px;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 16px;
      line-height: 24px;
      max-width: 270px; }

.plate {
  padding: 100px 0 205px;
  background-image: linear-gradient(to bottom, #a6c4eb, #7ca1d6 83%, #9dbcea); }
  @media only screen and (max-width: 480px) {
    .plate {
      padding: 60px 0 105px; } }
  .plate__title {
    color: #fff;
    text-align: center; }
  .plate__description {
    color: #fff;
    margin-left: auto;
    margin-right: auto; }
  .plate .image {
    margin: 145px auto 0;
    position: relative;
    max-width: 877px; }
    @media only screen and (max-width: 480px) {
      .plate .image {
        margin: 108px auto 0; } }
    .plate .image .capacity {
      position: absolute;
      top: 73px;
      left: 35px;
      width: 120px;
      height: 120px; }
      @media only screen and (max-width: 576px) {
        .plate .image .capacity {
          width: 96px;
          height: 96px;
          top: -40px;
          left: calc(50% - 43px); } }
    .plate .image img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }

.contact {
  padding: 105px 0;
  background-image: url("../img/contact-bg.png");
  background-repeat: no-repeat;
  background-position: 280px bottom; }
  @media only screen and (max-width: 1450px) {
    .contact {
      background-position: left bottom; } }
  @media only screen and (max-width: 992px) {
    .contact {
      background-image: none; } }
  @media only screen and (max-width: 480px) {
    .contact {
      padding: 60px 0; } }
  .contact .form {
    width: 555px;
    margin-left: auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.16); }
    @media only screen and (max-width: 992px) {
      .contact .form {
        width: 100%; } }
    .contact .form__title {
      color: #6613e2;
      font-family: "Comfortaa Bold", Arial, sans-serif;
      font-size: 38px;
      line-height: 42px;
      text-align: center;
      margin-top: 10px;
      letter-spacing: 2px; }
      @media only screen and (max-width: 480px) {
        .contact .form__title {
          font-size: 28px;
          line-height: 32px; } }
    .contact .form__first-row {
      margin-top: 10px;
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 576px) {
        .contact .form__first-row {
          flex-wrap: wrap; } }
    .contact .form__second-row {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 576px) {
        .contact .form__second-row {
          flex-wrap: wrap; } }
    .contact .form #your-first-name-field, .contact .form #your-second-name-field, .contact .form #your-email, .contact .form #your-phone {
      width: calc(50% - 15px);
      margin-top: 10px; }
      @media only screen and (max-width: 576px) {
        .contact .form #your-first-name-field, .contact .form #your-second-name-field, .contact .form #your-email, .contact .form #your-phone {
          width: 100%; } }
    .contact .form #your-company {
      margin-top: 10px; }
    .contact .form #your-message {
      margin-top: 30px; }
      .contact .form #your-message label {
        color: #606060;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px; }
      .contact .form #your-message textarea {
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 100px;
        border: solid 1px #606060;
        padding: 5px;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: #606060;
        resize: none; }
    .contact .form #submit {
      position: relative; }
      .contact .form #submit .ajax-loader {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }
    .contact .form input:not([type="submit"]) {
      display: block;
      width: 100%;
      border-bottom: 1px solid #606060;
      border-radius: 0 !important;
      padding: 20px 0;
      font-family: "Myriad Pro Regular", Arial, sans-serif;
      font-size: 18px;
      line-height: 24px;
      color: #606060; }
      .contact .form input:not([type="submit"]) ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #606060;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px; }
      .contact .form input:not([type="submit"]) ::-moz-placeholder {
        /* Firefox 19+ */
        color: #606060;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px; }
      .contact .form input:not([type="submit"]) :-ms-input-placeholder {
        /* IE 10+ */
        color: #606060;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px; }
      .contact .form input:not([type="submit"]) :-moz-placeholder {
        /* Firefox 18- */
        color: #606060;
        font-family: "Myriad Pro Regular", Arial, sans-serif;
        font-size: 18px;
        line-height: 24px; }
    .contact .form input[type="submit"] {
      display: block;
      width: 220px;
      height: 60px;
      border-radius: 5px;
      -webkit-appearance: none;
      background-color: #00db7c;
      margin: 30px auto 0;
      font-family: "Comfortaa Bold", Arial, sans-serif;
      font-size: 20px;
      line-height: 23px;
      color: #fff;
      cursor: pointer;
      transition: background-color .15s linear; }
      .contact .form input[type="submit"]:hover {
        background-color: #00a85f; }
  .contact .wpcf7-not-valid-tip, .contact .wpcf7-validation-errors, .contact .wpcf7-mail-sent-ok {
    font-family: "Myriad Pro Regular", Arial, sans-serif;
    margin-top: 5px;
    font-size: 14px; }
  .contact .wpcf7-validation-errors, .contact .wpcf7-mail-sent-ok {
    margin: 25px 0 0; }
  .contact .wpcf7-validation-errors {
    border: 1px solid #f00; }
  .contact .wpcf7-mail-sent-ok {
    border: 1px solid #008000; }

.image {
  margin: 30px;
  cursor: zoom-in; }

.popup {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
  text-align: center; }

.popup_bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto; }

.popup_img {
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 90%;
  max-width: 100%;
  min-width: 50%; }

.popup_img {
  pointer-events: none; }

.footer {
  background-color: #212121;
  color: #fff;
  font-family: "Myriad Pro Regular", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 60px 0 20px; }
  @media only screen and (max-width: 576px) {
    .footer {
      padding: 60px 0; } }
  .footer__row {
    display: flex;
    justify-content: space-between; }
    @media only screen and (max-width: 992px) {
      .footer__row {
        flex-wrap: wrap; } }
  .footer__col {
    width: 240px;
    padding: 10px; }
    @media only screen and (max-width: 992px) {
      .footer__col {
        width: 50%; } }
    @media only screen and (max-width: 576px) {
      .footer__col {
        width: 100%;
        text-align: center; } }
  .footer__copyright {
    margin-top: 70px;
    text-align: center;
    color: #cacaca; }
    @media only screen and (max-width: 576px) {
      .footer__copyright {
        margin-top: 40px; } }
  .footer__social ul {
    display: flex; }
    @media only screen and (max-width: 576px) {
      .footer__social ul {
        justify-content: center; } }
    .footer__social ul li:not(:last-child) {
      margin-right: 20px; }
    .footer__social ul li a {
      width: 20px;
      height: 20px;
      padding: 3px;
      background-color: #cacaca;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      transition: background-color .15s; }
      @media only screen and (max-width: 576px) {
        .footer__social ul li a {
          width: 30px;
          height: 30px; } }
      .footer__social ul li a:hover {
        background-color: #a1a1a1; }
      .footer__social ul li a.youtube {
        width: 27px; }
      .footer__social ul li a img {
        display: block;
        max-width: 100%; }

#scroll-top {
  width: 52px;
  height: 52px;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #fbb21b;
  border-radius: 50px;
  position: fixed;
  right: 70px;
  bottom: 70px;
  cursor: pointer;
  background-image: url(../img/arrow-top.svg);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center center;
  display: none; }
  @media only screen and (max-width: 480px) {
    #scroll-top {
      right: 35px;
      bottom: 35px; } }

html, body {
  overflow: hidden;
  overflow-y: auto; }
  html.overflow, body.overflow {
    overflow: hidden; }

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px; }
  @media only screen and (max-width: 1200px) {
    .container {
      max-width: 960px; } }
  @media only screen and (max-width: 992px) {
    .container {
      max-width: 720px; } }
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 540px; } }
  @media only screen and (max-width: 576px) {
    .container {
      max-width: none; } }

.section__title {
  font-family: "Comfortaa Bold", Arial, sans-serif;
  font-size: 38px;
  line-height: 42px; }
  @media only screen and (max-width: 480px) {
    .section__title {
      font-size: 28px;
      line-height: 32px; } }

.section__description {
  max-width: 500px;
  margin-top: 30px;
  font-family: "Myriad Pro Regular", Arial, sans-serif;
  font-size: 16px;
  line-height: 24px; }
  @media only screen and (max-width: 480px) {
    .section__description {
      font-size: 14px;
      line-height: 18px; } }
