*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0); 
    -webkit-tap-highlight-color: transparent;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
}

input, button {
    border: none;
    &:focus {
        outline: none;
    }
}

body {
    &.overflow-active {
        overflow: hidden;
    }
}