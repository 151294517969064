.header {
    background-image: linear-gradient(to left, #fed200, #ec3386);
    color: #fff;
    &__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $fontComfortaaBold;
        font-size: 14px;
        line-height: 15px;
        color: #fff;
        padding: 35px 0;
        &::before {
            @include mediaw(830px) {
                content: " ";
                transform: translateX(100%);
                transition: transform .15s linear;
                display: block;
                width: calc(100% + 30px);
                height: 100px;
                background-image: linear-gradient(271deg,#f7992f,#f26b56);
                position: fixed;
                top: 0;
                left: -15px;
                z-index: 2;
            }
        }
        &.active {
            &::before {
                @include mediaw(830px) {
                    transform: translateX(0);
                }
            }
        }
    }
    &__lang-and-menu--wrapper {
        width: 100%;
        padding: 0 40px;
        @include mediaw(992px) {
            padding: 0 20px;
        }
        @include mediaw(830px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            // height: 100vh;
            // overflow: auto;
            overflow: -moz-scrollbars-none; // Hide scroll bar, but while still being able to scroll for Firefox
            -ms-overflow-style: none; // Hide scroll bar, but while still being able to scroll for Internet Explorer +10
            transform: translateX(100%);
            background-image: linear-gradient(271deg, #f7992f, #f26b56);
            transition: transform .15s linear;
            z-index: 1;
            padding: 135px 15px;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            display: block;
            height: auto;
            min-height: 100vh;
            max-height: 100vh;
            overflow: auto;
        }
        &::-webkit-scrollbar {
            @include mediaw(830px) {
                width: 0 !important; // Hide scroll bar, but while still being able to scroll for Chrome, Safari, newer versions of Opera
            }
        }
        &.active {
            transform: translateX(0);
        }
    }
    &__lang-and-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mediaw(830px) {
            // flex-direction: column-reverse;
            // justify-content: flex-end;
            // overflow: auto;
            display: block;
            max-height: calc(100vh - 300px);
            overflow: -moz-scrollbars-none; // Hide scroll bar, but while still being able to scroll for Firefox
            -ms-overflow-style: none; // Hide scroll bar, but while still being able to scroll for Internet Explorer +10
        }
        &::-webkit-scrollbar {
            @include mediaw(830px) {
                width: 0 !important; // Hide scroll bar, but while still being able to scroll for Chrome, Safari, newer versions of Opera
            }
        }
    }
    &__menu {
        display: flex;
        align-items: center;
        @include mediaw(830px) {
            padding-bottom: 150px;
        }
        ul {
            display: flex;
            align-items: center;
            @include mediaw(830px) {
                // flex-direction: column;
                align-items: unset;
                display: block;
                margin: 0 auto;
                font-size: 28px;
                line-height: 32px;
                text-align: center;
            }
            li {
                position: relative;
                &:not(:last-child) {
                    margin-right: 40px; 
                    @include mediaw(830px) {
                        margin-right: 0;
                        margin-bottom: 30px;
                    }
                }
                @include mediaw(830px) {
                    position: static;
                }
                &.certificates-btn {
                    position: relative;
                    @include mediaw(830px) {
                        // position: static;
                    }
                    .certificates {
                        position: absolute;
                        top: 45px;
                        left: -746px;
                        width: 1140px;
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                        background-color: #f0f0f0;
                        border-radius: 15px;
                        padding: 30px 80px 30px 40px;
                        z-index: 1;
                        color: #606060;
                        font-family: $fontMyriadRegular;
                        font-size: 16px;
                        line-height: 24px;
                        display: none;
                        &::before {
                            @include mediaw(830px) {
                                content: '';
                                border: 15px solid transparent;
                                border-bottom: 15px solid #f0f0f0;
                                position: absolute;
                                top: 32px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                        &::after {
                            content: ''; 
                            position: absolute;
                            top: -30px;
                            left: calc(50% - -235px);
                            transform: translateX(-50%);
                            border: 15px solid transparent;
                            border-bottom: 15px solid #f0f0f0;
                            @include mediaw(1199px) {
                                left: calc(50% - -105px);
                            }
                            @include mediaw(992px) {
                                left: calc(50% - -55px);
                            }
                            @include mediaw(830px) {
                                display: none;
                            }
                        }
                        @include mediaw(1199px) {
                            padding: 20px;
                            left: -450px;
                            width: 800px;
                        }
                        @include mediaw(992px) {
                            left: -400px;
                        }
                        @include mediaw(830px) {
                            position: static;    
                            margin-top: 30px;
                            padding: 30px 50px 20px;
                            opacity: 1;
                            width: initial;
                            // display: block;
                            overflow: auto;
                            overflow: -moz-scrollbars-none; // Hide scroll bar, but while still being able to scroll for Firefox
                            -ms-overflow-style: none; // Hide scroll bar, but while still being able to scroll for Internet Explorer +10
                            height: 400px;
                        }
                        // @include mediah(600px) {
                        //     height: 2600px;
                        // }
                        &::-webkit-scrollbar {
                            @include mediaw(830px) {
                                width: 0 !important; // Hide scroll bar, but while still being able to scroll for Chrome, Safari, newer versions of Opera
                            }
                        }
                        &__title {
                            font-family: $fontComfortaaBold;
                            font-size: 24px;
                            line-height: 27px;
                            color: #242424;
                            padding-top: 40px;
                        }
                        &__description {
                            margin-top: 20px;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        &__row {
                            display: flex;
                            justify-content: space-between;
                        }
                        &__col {
                            &:first-child {
                                width: 200px;
                                @include mediaw(830px) {
                                    display: none;
                                }
                            }
                            &:nth-child(2) {
                                width: 320px;
                                .certificate__current-image {
                                    display: block;
                                    margin: 0 auto;
                                    max-width: 320px;
                                    max-height: 450px;
                                    @include mediaw(992px) {
                                        max-width: 285px;
                                    }
                                }
                                @include mediaw(830px) {
                                    display: none;
                                }
                            }
                            &:last-child {
                                width: 330px;
                                display: flex;
                                flex-wrap: wrap; 
                                justify-content: space-between;
                                @include mediaw(830px) {
                                    width: 100%;
                                }
                                .certificate__item {
                                    width: calc(50% - 10px);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    align-items: flex-start;
                                    @include mediaw(830px) {
                                        display: block;
                                        width: 100%;
                                    }
                                    img {
                                        display: block;
                                        max-width: 100%;
                                        margin: 0 auto;
                                        width: 166px;
                                        height: 232px;
                                    }
                                    p {
                                        display: none;
                                        @include mediaw(830px) {
                                            display: block;
                                            text-align: center;
                                        }
                                    }
                                    &:nth-child(3) {
                                        margin-top: 15px;
                                    }
                                    &:last-child {
                                        margin-top: 15px;
                                    }
                                }
                            }
                        }
                        .certificate__current {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .certificate__item {
                            position: relative;
                            cursor: pointer;
                            .watch {
                                opacity: 0;
                                transition: opacity .15s linear;
                                display: block;
                                width: 65px;
                                height: 65px;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translateX(-50%) translateY(-50%);
                                background-image: url(../img/eye.svg);
                                background-size: 50%;
                                background-repeat: no-repeat;
                                background-position: center center;
                                box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
                                background-color: #f15b65;
                                border-radius: 50%;
                                @include mediaw(830px) {
                                    display: none;
                                }
                            }
                            &:hover .watch {
                                opacity: 1;
                            }
                        }
                    }
                }
                &.dropdown {
                    padding-right: 23px;
                    background-image: url(../img/arrow-down.svg);
                    background-position: right 7px;
                    background-size: 15px 6.5px;
                    background-repeat: no-repeat;
                    @include mediaw(992px) {
                        padding-right: 15px;
                    }
                    @include mediaw(830px) {
                        background-image: none;
                        padding-right: 0;
                    }
                    span {
                        @include mediaw(830px) {
                            display: inline-block;
                            width: 17px;
                            height: 10px;
                            margin: 0 0 2px 7px;
                            background-image: url(../img/arrow-down.svg);
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }
                    ul.sub-menu {
                        position: absolute;
                        top: 20px;
                        left: 50%;
                        width: 200px;
                        -webkit-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                        font-family: "Comfortaa Bold",Arial,sans-serif;
                        display: block;
                        padding: 10px 10px 25px;
                        border-radius: 5px;
                        font-size: 14px;
                        line-height: 15px;
                        color: #000;
                        background-color: #fff;
                        display: none;
                        z-index: 1;
                        @include mediaw(830px) {
                            opacity: 1;
                            transform: none;
                            margin-top: 40px;
                            display: none;
                            background-color: transparent;
                            color: #fff;
                            width: 100%;
                            text-align: left;
                            font-size: 24px;
                            line-height: 27px;
                        }
                        &::before {
                            content: " ";
                            position: absolute;
                            top: -4px;
                            left: calc(50% - 6px);
                            border-radius: 2px;
                            width: 12px;
                            height: 12px;
                            transform: rotate(45deg);
                            background-color: #fff;
                            @include mediaw(830px) {
                                // top: 41px;
                                // left: initial;
                                display: none;
                            }
                        }
                        li {
                            &:first-child a {
                                margin-top: 0;
                            }
                            &:last-child a {
                                margin-bottom: 0;
                            }
                            a {
                                display: inline-block;
                                margin: 3px 0;
                                &:hover {
                                    text-decoration: underline;
                                }
                                @include mediaw(830px) {
                                    margin: 0;
                                }
                            }
                            &:not(:first-child) {
                                @include mediaw(830px) {
                                    margin-top: 30px;
                                }
                            }
                            &:not(:last-child) {
                                @include mediaw(830px) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        @include mediaw(830px) {
                            position: static;
                        }
                    }
                    // &:hover ul.sub-menu {
                    //     opacity: 1;
                    // }
                }
            }
        }
    }
    &__language-switcher {
        cursor: pointer;
        position: relative;
        background-image: url(../img/arrow-down.svg);
        background-position: right 7px;
        background-size: 11.4px 6.5px;
        background-repeat: no-repeat;
        padding-right: 21.4px;
        @include mediaw(830px) {
            // margin: 50px 0;
            background: none;
            position: static;
            // position: absolute;
            // left: 50%;
            // transform: translateX(-50%);
            padding-right: 0;
            // bottom: 50px;
            // transform: translateY(450px);
            margin-bottom: 25px;
        }
        @include mediah(600px) {
            margin-top: 60px;
        }
        &:hover .languages {
            opacity: 1;
        }
        span {
            @include mediaw(830px) {
                display: none;
            }
        }
        .languages {
            position: absolute;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
            font-family: $fontComfortaaBold;
            display: block;
            padding: 10px;
            border-radius: 5px;
            font-size: 14px;
            line-height: 15px;
            color: #000;
            background-color: #fff;
            opacity: 0;
            transition: opacity .3s linear;
            @include mediaw(830px) {
                position: static;
                opacity: 1;
                padding: 0;
                border-radius: 0;
                transition: none;
                background: none;
                display: flex;
                transform: none;
                justify-content: center;
            }
            li {
                @include mediaw(830px) {
                    font-size: 18px;
                    line-height: 32px;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: 2px solid rgba(112, 112, 112, 0.3);
                    color: #fff;
                }
                &:first-child {
                    @include mediaw(830px) {
                        margin-right: 15px;
                    }
                }
            }
            .current-lang {
                display: none;
                @include mediaw(830px) {
                    display: flex;
                    background-color: rgba(33, 33, 33, 0.3);
                    border: none;
                }
            }
            &::before {
                content: " ";
                position: absolute;
                top: -4px;
                left: 12px;
                border-radius: 2px;
                width: 12px;
                height: 12px;
                transform: rotate(45deg);
                background-color: #fff;
                @include mediaw(830px) {
                    display: none;
                }
            }
        }
    }
    &__contact-btn {
        width: 170px;
        height: 40px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #606060;
        position: relative;
        z-index: 2;
        @include mediaw(480px) {
            width: 138px;
        }
    }
    &__logo {
        position: relative;
        z-index: 2;
        @include mediaw(480px) {
            max-width: 136px;
        }
    }
    &__hamburger-btn {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        display: none;
        z-index: 777;
        @include mediaw(830px) {
            display: flex;
        }
        span {
            display: block;
            width: 100%;
            background-color: rgba(255,255,255,0.8);
            position: relative;
            height: 3px;
            border-radius: 3px;
            transition: .2s linear;
            &.active {
                background-color: transparent;
                &::before {
                    transform: rotate(45deg);
                    top: 0;
                    left: 0;
                }
                &::after {
                    transform: rotate(-45deg);
                    top: 0;
                    left: 0;
                }
            }
            &::before {
                content: " ";
                display: block;
                width: 100%;
                background-color: rgba(255,255,255,0.8);
                position: absolute;
                left: 0;
                top: -13px;
                height: 3px;
                border-radius: 3px;
                transition: .2s linear;
            }
            &::after {
                content: " ";
                border-radius: 3px;
                transition: .2s linear;
                height: 3px;
                left: 0;
                bottom: -13px;
                display: block;
                width: 100%;
                background-color: rgba(255,255,255,0.8);
                position: absolute;
            }
        }
    }
}