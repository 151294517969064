@font-face {
    font-family: 'Myriad Pro Regular';
    src: url('../fonts/MyriadPro-Regular.eot');
    src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/MyriadPro-Regular.woff') format('woff'),
    url('../fonts/MyriadPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Comfortaa Bold';
    src: url('../fonts/Comfortaa-Bold.eot');
    src: url('../fonts/Comfortaa-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Comfortaa-Bold.woff') format('woff'),
    url('../fonts/Comfortaa-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}