.footer {
    background-color: #212121;
    color: #fff;
    font-family: $fontMyriadRegular;
    font-size: 16px;
    line-height: 24px;
    padding: 60px 0 20px;
    @include mediaw(576px) {
        padding: 60px 0;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        @include mediaw(992px) {
            flex-wrap: wrap;
        }
    }
    &__col {
        width: 240px;
        padding: 10px;
        @include mediaw(992px) {
            width: 50%;
        }
        @include mediaw(576px) {
            width: 100%;
            text-align: center;
        }
    }
    &__copyright {
        margin-top: 70px;
        text-align: center;
        color: #cacaca;
        @include mediaw(576px) {
            margin-top: 40px;
        }
    }
    &__social {
        ul {
            display: flex;
            @include mediaw(576px) {
                justify-content: center;
            }
            li {
                &:not(:last-child) {
                    margin-right: 20px;
                }
                a {
                    width: 20px;
                    height: 20px;
                    padding: 3px;
                    background-color: #cacaca;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    transition: background-color .15s;
                    @include mediaw(576px) {
                        width: 30px;
                        height: 30px;
                    }
                    &:hover {
                        background-color: #a1a1a1;
                    }
                    &.youtube {
                        width: 27px;
                    }
                    img {
                        display: block;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

#scroll-top {
    width: 52px;
    height: 52px;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    background-color: #fbb21b;
    border-radius: 50px;
    position: fixed;
    right: 70px;
    bottom: 70px;
    cursor: pointer;
    background-image: url(../img/arrow-top.svg);
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center center;
    display: none;
    @include mediaw(480px) {
        right: 35px;
        bottom: 35px;
    }
}